/* eslint-disable react/style-prop-object */
import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div class="main-area center-text background">
        <div class="display-table">
          <div class="display-table-cell">
          <h1 class="title font-white"><b>Under Construction</b></h1>
          <p class="desc font-white">Our website is currently undergoing development phase.
            We Should be live soon. Thank you for your patience.</p>
                
          <ul class="social-btn font-white">
            <li><a href="https://www.facebook.com/arbaeendiary/">facebook</a></li>
            <li><a href="http://instagram.com/arbaeendiary">instagram</a></li>
            <li><a href="http://arbaeendiary.tumblr.com">tumblr</a></li>
            <li><a href="http://twitter.com/arbaeendiary">twitter</a></li>
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
